import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import All_Doctor from "./All_Doctor";
import Breadcrumb from "../../../components/Breadcrumb";
import download from "../../../Data/download.png";
import search from "../../../Data/search.png";
import generatePDF from "react-to-pdf";
import baseURL from "../../../assets/API_URL";
import DoctorDropDown from "../../../components/DropDown/DoctorDropDown";
import CustomDropdown from "../../../components/DropDown/CustomDropdown";

const Doctor_Price = () => {
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [doctorInput, setDoctorInput] = useState("");
  const [status, setStatus] = useState("");
  const [showDoctorDropdown, setShowDoctorDropdown] = useState(false);
  const [formData, setFormData] = useState({
    doctor: selectedDoctor,
    price: "",
    type:status,
  });
 
 // console.log("stat" , status)
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      doctor: selectedDoctor,
      type: status,  
    }));
  }, [selectedDoctor , status]);

  const token = JSON.parse(localStorage.getItem("Token"));
  const navigate = useNavigate();

  const handle = () => {
    navigate("/Doctor/Details");
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("Selected Doctor:", selectedDoctor);
    // console.log("Form Data Before Submit:", formData);
    axios
      .post(`${baseURL}/doctor/doctors/visit-doctor-prices/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        // console.log("API Response:", response.data);
        window.location.reload();
        
      })
      .catch((error) => {
        // console.error("API Error:", error);
        // console.log("Error response data:", error.response?.data);
      });
  };

  const targetRef = useRef();
  const [searh, setSearch] = useState("");
  // console.log("doc0,", selectedDoctor);
  const statusOptions = [
    { value: "", label: "Select the option" },
    { value: "Appointment", label: "Appointment" },
    { value: "specialist", label: "specialist" },
    { value: "other", label: "other" },
  
  ];
  return (
    <div className="">
      <Breadcrumb></Breadcrumb>
      <div className="relative bg-theme-white-default px-[30px] h-20  overflow-hidden shrink-0 mt-5 w-[1220px] ml-5">
        <div className="absolute top-[0px] left-[10px] w-[1000px] flex flex-col items-start justify-start">
          <div className="self-stretch relative h-[60px] overflow-hidden shrink-0">
            <div className="absolute w-full top-[60px] right-[0px] left-[0px] bg-gray-200 box-border h-0 border-t-[1px] border-solid border-border-light" />
            <div className="absolute top-[18px] left-[22px] text-[20px] text-slate-700 leading-[24px] font-medium">
              Doctor Charges
            </div>
          </div>
        </div>
      </div>
      <div className="ml-[60px]">
        <fieldset>
          <form
            onSubmit={handleSubmit}
            className="m-0 w-[1110px] bg-theme-white-default  flex flex-col py-6 pr-[22px] ml-[26px] box-border gap-[30px] mt-5 mx-6 mb-10 "
          >
            <div className="flex flex-col px-7 mt-6 max-md:px-5 max-md:max-w-full">
              <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
                <div className="flex mt-1 flex-col flex-1 self-start">
                  <div className="text-sm text-slate-600 font-medium">
                    Doctor Name
                  </div>
                  <div className=" dropdown-menu mt-5 ">
                  <DoctorDropDown
                    selectedDoctor={selectedDoctor}
                    setSelectedDoctor={setSelectedDoctor}
                    doctorInput={doctorInput}
                    setDoctorInput={setDoctorInput}
                    showDoctorDropdown={showDoctorDropdown}
                    setShowDoctorDropdown={setShowDoctorDropdown}
                  />
                  </div>
                </div>

                <div className="flex flex-col flex-1 py-0.5 max-md:max-w-full">
                  <div className="text-sm text-slate-600 font-medium max-md:max-w-full">
                    Doctor Charges
                  </div>
                  <input
                    className="justify-center items-start py-4 pr-16 pl-4 mt-3 text-base leading-4 text-gray-500 whitespace-nowrap rounded-md bg-slate-100 max-md:pr-5 max-md:max-w-full"
                    type="number"
                    placeholder="Enter the charge of doctor"
                    value={formData.price}
                    onChange={handleChange}
                    name="price"
                  ></input>
                </div>
              </div>
            </div>
            <div className="flex flex-col px-7 mt-6 max-md:px-5  w-[500px]">
              <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full"></div>
              <div className="flex mt-1 flex-col flex-1 self-start">
              <div className="text-sm text-slate-600 font-medium">
                Type of Charge
              </div>
              <div className="  mt-0 w-[500px]">
              <CustomDropdown
              options={statusOptions}
              value={status}
              onChange={setStatus}
            />
              </div>
            </div></div>

            <div className="flex items-stretch justify-between gap-5 mt-8 self-end">
              <button
                onClick={handle}
                className="text-blue-700 text-base font-semibold leading-4 items-stretch border grow justify-center px-8 py-4 rounded-lg border-solid border-blue-700 max-md:px-5"
              >
                Cancel
              </button>
              <button className="text-white text-base font-semibold leading-4 items-stretch border border-[color:var(--Theme-Primary-Default,#4C6FFF)] bg-blue-700 grow justify-center px-7 py-4 rounded-lg border-solid max-md:px-5">
                Submit
              </button>
            </div>
          </form>
        </fieldset>
      </div>
      <All_Doctor></All_Doctor>
    </div>
  );
};

export default Doctor_Price;
