import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import baseURL from "../../../assets/API_URL";
import Breadcrumb from "../../../components/Breadcrumb";
const steps = [
  "Basic Details",
  "Operation Note ",
  "Insurance Details",
  "Deposit Details",
];
const Edit_Discharged = () => {
  let { admission_id } = useParams();
  let { pk} = useParams();
  const today = new Date().toISOString().split("T")[0];
  const token = JSON.parse(localStorage.getItem("Token"));
  const [formData, setFormData] = useState({
    diagnosis: "",
    co : "",
    history :"",
    finding :"",
    operation_date:"",
    operation_notes:"",
    course_in_hospital:"",
    investigation:"",
    treatment_advised:"",
    follow_up:"",
    admission:admission_id,
    discharge_date:today
  });

  useEffect(() => {
    if (pk) {
      axios.get(`${baseURL}/ipd/ipd-DischargeHistory/${pk}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setFormData(response.data);
        // console.log("data", response.data);
      })
      .catch((error) => {
        // console.error("Error fetching appointment data:", error);
        // console.log("Error response data:", error.response?.data);
      });
    }
  }, [pk, token]);
  
  const [activeStep, setActiveStep] = useState(0);
 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Set isSubmitting to true
    const token = JSON.parse(localStorage.getItem("Token"));
    formData.owner_token = token;
    axios
      .put(`${baseURL}/ipd/ipd-DischargeHistory/${pk}/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        // console.log("Discharge data sent:", res.data);
        navigate("/Patient/IPD");
      })
      .catch((error) => {
        // console.error("Error sending discharge data:", error);
        // console.log("Error response data:", error.response?.data);
        alert("Not discharged. Some error occurred.");
      })
      .finally(() => {
        setIsSubmitting(false); // Reset isSubmitting to false
      });
  };
  

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <Breadcrumb></Breadcrumb>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className="  mt-10 w-[1210px] ">
          <form>
            {activeStep === 0 && (
              <div className="flex flex-col w-[1220px]  mx-4 mt-5 px-6 pb-12 font-medium bg-slate-50 max-md:px-5">
                <div className="flex flex-col pt-6 pb-12 bg-white max-md:max-w-full">
                  <div className="self-start ml-7 text-xl leading-6 whitespace-nowrap text-slate-800 max-md:ml-2.5"></div>
                  <div className="shrink-0 mt-5 h-px bg-slate-100 max-md:max-w-full" />
                  <div className="flex flex-col px-7 mt-6 max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
                      <div className="flex mt-1 flex-col flex-1 self-start">
                        <div className="text-sm text-slate-600 ml-2">
                          Diagnosis{" "}
                        </div>
                        <textarea
                          className="flex gap-5  justify-between p-4 mt-5 text-base leading-4 h-20 text-gray-500 rounded-md bg-slate-100"
                          name="diagnosis"
                          type="text"
                          onChange={handleChange}
                          value={formData.diagnosis}
                          placeholder="enter the Diagnosis"
                        ></textarea>
                      </div>

                      <div className="flex mt-1 flex-col flex-1 self-start">
                        <div className="text-sm text-slate-600">C/O </div>
                        <textarea
                          className="flex gap-5 justify-between p-4 mt-5 h-20 text-base leading-4 text-gray-500 rounded-md bg-slate-100 "
                          name="co"
                          type="text"
                          onChange={handleChange}
                          value={formData.co}
                          placeholder="enter the C/O "
                        ></textarea>
                      </div>
                    </div>
                    <div className="flex mt-4 gap-5 justify-between mt-8 max-md:flex-wrap max-md:max-w-full">
                      <div className="flex mt-1 flex-col flex-1 self-start">
                        <div className="text-sm text-slate-600">History </div>
                        <textarea
                          className="flex gap-5 justify-between p-4 mt-5 h-20 text-base leading-4 text-gray-500 rounded-md bg-slate-100"
                          name="history"
                          type="text"
                          onChange={handleChange}
                          value={formData.history}
                          placeholder="enter the History"
                        ></textarea>
                      </div>
                      <div className="flex mt-1 flex-col flex-1 self-start">
                        <div className="text-sm text-slate-600">Findings </div>
                        <textarea
                          className="flex gap-5 justify-between p-4 mt-5 h-20 text-base leading-4 text-gray-500 rounded-md bg-slate-100"
                          name="finding"
                          type="text"
                          onChange={handleChange}
                          value={formData.finding}
                          placeholder="enter the Findings"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <div className="flex flex-col w-[1220px]  mx-4 mt-0 px-6 pb-12 font-medium bg-slate-50 max-md:px-5">
                <div className="flex flex-col pt-6 pb-12 bg-white max-md:max-w-full">
                  <div className="flex flex-col gap-5 ml-12 mt-10">
                    <div className="flex flex-col ml-0">
                      <div className="text-slate-600 text-sm font-medium">
                        Operation Notes
                      </div>
                      <textarea
                        className="h-40 w-[920px] p-4 mt-5 text-base leading-4 text-gray-700 rounded-md bg-slate-100"
                        placeholder="operation notes.."
                        name="operation_notes"
                        value={formData.operation_notes}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="flex flex-col mt-5 w-[300px]">
                      <div className="text-sm text-slate-600 font-medium">
                        Operation Date
                      </div>
                      <input
                        className="py-4 pr-2 pl-4 mt-5 text-base leading-4 text-gray-500 rounded-md bg-slate-100"
                        placeholder="Enter discharge date"
                        type="date"
                        name="operation_date"
                        value={formData.operation_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 2 && (
              <div className="flex flex-col w-[1220px]  mx-4 mt-5 px-6 pb-12 font-medium bg-slate-50 max-md:px-5">
                <div className="flex flex-col pt-6 pb-12 bg-white max-md:max-w-full">
                  <div className="self-start ml-7 text-xl leading-6 whitespace-nowrap text-slate-800 max-md:ml-2.5"></div>
                  <div className="shrink-0 mt-5 h-px bg-slate-100 max-md:max-w-full" />
                  <div className="flex flex-col px-7 mt-6 max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
                      <div className="flex mt-1 flex-col flex-1 self-start">
                        <div className="text-sm text-slate-600 ml-2">
                          Course in Hospital{" "}
                        </div>
                        <textarea
                          className="flex gap-5  justify-between p-4 mt-5 text-base leading-4 h-36 text-gray-500 rounded-md bg-slate-100"
                          name="course_in_hospital"
                          type="text"
                          onChange={handleChange}
                          value={formData.course_in_hospital}
                          placeholder="enter the course in hospital"
                        ></textarea>
                      </div>

                      <div className="flex mt-1 flex-col flex-1 self-start">
                        <div className="text-sm text-slate-600">
                        Investigation{" "}
                        </div>
                        <textarea
                          className="flex gap-5 justify-between p-4 mt-5 h-36 text-base leading-4 text-gray-500 rounded-md bg-slate-100 "
                          name="investigation"
                          type="text"
                          onChange={handleChange}
                          value={formData.investigation}
                          placeholder="enter the investigation"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 3 && (
              <div className="flex flex-col w-[1220px]  mx-4 mt-0 px-6 pb-12 font-medium bg-slate-50 max-md:px-5">
                <div className="flex flex-col pt-6 pb-12 bg-white max-md:max-w-full">
                  <div className="flex flex-col gap-5 ml-12 mt-0">
                    <div className="flex mt-1 flex-col flex-1 self-start w-[600px]">
                      <div className="text-sm text-slate-600">
                        Treatment Advised{" "}
                      </div>
                      <textarea
                        className="flex gap-5 justify-between p-4 mt-5 h-20 text-base leading-4 text-gray-500 rounded-md bg-slate-100 "
                        name="treatment_advised"
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        value={formData.treatment_advised}
                      
                      ></textarea>
                    </div>
                    <div className="flex flex-col ml-0">
                      <div className="text-slate-600 text-sm font-medium">
                        Follow up
                      </div>
                      <textarea
                        className="h-44 w-[920px] p-4 mt-5 text-base leading-4 text-gray-700 rounded-md bg-slate-100"
                        placeholder="follow up"
                        name="follow_up"
                        value={formData.follow_up}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Box
              sx={{ display: "flex", justifyContent: "flex-end", marginTop: 0 }}
            >
              <Button
                variant="outlined"
                size="medium"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={
                  activeStep === steps.length - 1 ? handleSubmit : handleNext
                }
              >
                {activeStep === steps.length - 1 ? "Save" : "Next"}
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </div>
  );
};

export default Edit_Discharged;
