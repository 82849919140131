import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import HospitalHeader from '../../components/Billing/HospitalHeader';
import print from "../../Data/print.svg";
import download from "../../Data/download.svg";
import generatePDF from "react-to-pdf";

import Breadcrumb from '../../components/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import baseURL from '../../assets/API_URL';
import axios from 'axios';
import { format } from 'date-fns';

const Appointment_Billing = () => {
  const { pk } = useParams();
  const targetRef = useRef();
  const pdfRef = useRef();
  const navigate = useNavigate();
  const [appointmentData, setAppointmentData] = useState([]);
  const [doctorPriceData, setDoctorPriceData] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [filteredAppointment, setFilteredAppointment] = useState(null);
  const [doctorPrice, setDoctorPrice] = useState(null);
  const [ billamount , setBillamount] = useState([]);

  const billingAPI = `${baseURL}/patient/api/patient-billings/`;
  const appointmentsAPI = `${baseURL}/api/appointment/appointments/`;
  const priceAPI = `${baseURL}/doctor/doctors/visit-doctor-prices/`;
  const doctorAPI = `${baseURL}/doctor/api/doctors/`;
  const patientAPI = `${baseURL}/api/patient/api/patients/`;
  const token = JSON.parse(localStorage.getItem("Token"));

  useEffect(() => {
    const fetchAppointmentData = async () => {
      try {
        const response = await axios.get(appointmentsAPI, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const doctorPriceRes = await axios.get(priceAPI, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const patientRes = await axios.get(patientAPI, {
          headers: {
            Authorization: `Token ${token}`,
          }
        });

        const doctorRes = await axios.get(doctorAPI, {
          headers: {
            Authorization: `Token ${token}`,
          }
        });

        setAppointmentData(response.data);
        setDoctorPriceData(doctorPriceRes.data);
        setPatientList(patientRes.data);
        setDoctorList(doctorRes.data);

        // Filter appointment based on pk
        const appointment = response.data.find(app => app.id === parseInt(pk, 10));
        setFilteredAppointment(appointment);

        if (appointment) {
           const filteredPrices = doctorPriceRes.data.filter(price => price.type === 'Appointment');
           const price = filteredPrices.find(price => price.doctor === appointment.doctor);
         // const price = doctorPriceRes.data.find(price => price.doctor === appointment.doctor);
          setDoctorPrice(price);
        }

        // console.log("Appointment data:", response.data);
        // console.log("Doctor price data:", doctorPriceRes.data);
      } catch (error) {
        // console.error("Error fetching appointment data:", error);
        // console.log("Error response data:", error.response?.data);
      }
    };

    fetchAppointmentData();
  }, [appointmentsAPI, priceAPI, token, pk]);
  const patientID = filteredAppointment ? filteredAppointment.patient : 'N/A'
  const generateFinalBill = async (event) => {
    event.preventDefault();
    const patientID = filteredAppointment ? filteredAppointment.patient : 'N/A'
    if (!patientID || !doctorPrice) {
      // console.error("Patient ID or Doctor Price is missing");
      alert("Please make sure all required fields are filled out.");
      return;
    }
  
    
    // console.log("Patient ID:", patientID);
    const invoiceDetails = doctorPrice.price; // Assuming you want to send the price as the invoice details
  
    try {
      const response = await axios.post(billingAPI, {
        patientID: patientID,
        InvoiceDetails: invoiceDetails,
      }, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
  
      // console.log("Bill generated successfully:", response.data);
      alert("Bill generated successfully.");
    } catch (error) {
      // console.error("Error generating bill:", error);
      // console.log("Error response data:", error.response?.data);
    }
  };
  
  const formattedDate = filteredAppointment ? format(new Date(filteredAppointment.time_slot), 'dd-MM-yyyy') : 'N/A';
  const formattedTime = filteredAppointment 
  ? format(new Date(filteredAppointment.time_slot), 'hh:mm a') 
  : 'N/A';
  const doctorMap = doctorList.reduce((map, doctor) => {
    map[doctor.DoctorID] = doctor.name;
    return map;
  }, {});

  const patientMap = patientList.reduce((map, patient) => {
    map[patient.PatientID] = patient.fullname;
    return map;
  }, {});

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
    documentTitle: "Print Appointment",
    removeAfterPrint: true,
  });
  const handle = () => {
    navigate("/Appointment");
  };
  return (
    <div className='ml-40 mt-10'>
      <div ref={targetRef}>
     <div>
     <div ref={pdfRef}>
        <div className="flex flex-col pt-5 pb-20 text-sm font-medium text-black bg-white max-w-[869px]">
          <div className="flex flex-col items-start px-9 w-full max-md:px-5 max-md:max-w-full">
            <div className='w-[800px]'>
              <HospitalHeader />
            </div>
            <div className="flex gap-5 py-0.5 mt-10">
              <div className="flex-auto ml-4 font-bold">Bill No</div>
              <div className='ml-[108px] font-bold'>:</div>
              <div className="font-bold ml-4">{filteredAppointment ? filteredAppointment.id : 'N/A'}</div>
            </div>
            <div className="flex gap-5 py-0.5 mt-2.5">
              <div className="flex-auto ml-4 font-bold">Appointment Date</div>
              <div className='ml-[30px] font-bold'>:</div>
              <div className="font-bold ml-4">{formattedDate}</div>
            </div>
            <div className="flex gap-5 py-0.5 mt-2.5">
              <div className="flex-auto ml-4 font-bold">Appointment Time</div>
              <div className='ml-7 font-bold'>:</div>
              <div className="font-bold ml-4">{formattedTime}</div>
            </div>
            <div className="flex gap-5 py-0.5 mt-2.5">
              <div className="flex-auto ml-4 font-bold">Patient Name</div>
              <div className='ml-16 font-bold'>:</div>
              <div className="font-bold ml-4">{filteredAppointment && patientMap[filteredAppointment.patient] ? patientMap[filteredAppointment.patient] : 'Unknown'}</div>
            </div>
            <div className="flex gap-5 py-0.5 mt-2.5">
              <div className="flex-auto ml-4 font-bold">Appointment Charges</div>
              <div className='ml-[10px] font-bold'>:</div>
              <div className="font-bold ml-4">{doctorPrice ? `${doctorPrice.price} /-` : 'N/A'}</div>
            </div>
           
            <div className="self-stretch mt-10 w-full border border-black border-solid stroke-[1px] stroke-black max-md:max-w-full" />
            <div className="self-center mt-4 mb-2 text-8xl font-extrabold tracking-wider">
              Bill Details
            </div>
          </div>
          <div className="flex flex-col px-9 mt-2 w-full max-md:px-5 max-md:max-w-full">
            {/* Image and other content */}
            <div
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/adc93c4a6eb0d9c2d483e8a7f72ee0498ad784ea45692e232aedf7c6b41c119f?apiKey=8d6992485656477797592f8415f51272&"
              className="w-full border border-black border-solid stroke-[1px] stroke-black max-md:max-w-full"
            />
            <div className="flex gap-5 justify-between mt-1.5 w-full max-md:flex-wrap max-md:max-w-full">
              <div className="flex gap-5 justify-between font-bold">
                <div className="ml-5">Sr . no</div>
                <div className="ml-11">Doctor Name</div>
              </div>
              <div className="mr-10 font-bold">Amount</div>
            </div>
            <div
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/adc93c4a6eb0d9c2d483e8a7f72ee0498ad784ea45692e232aedf7c6b41c119f?apiKey=8d6992485656477797592f8415f51272&"
              className="mt-1.5 w-full border border-black border-solid stroke-[1px] stroke-black max-md:max-w-full"
            />
            <div className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
              <div className="flex gap-5 justify-between">
                <div className="flex flex-col self-start tracking-tight">
                  <div className='mt-4 ml-8 font-bold'>1.</div>
                </div>
                <div className="flex flex-col">
                  <div className="mt-4 mb-3 ml-16 font-bold">Dr.  {filteredAppointment && doctorMap[filteredAppointment.doctor] ? doctorMap[filteredAppointment.doctor] : 'Unknown'}</div>
                </div>
              </div>
              <div className="flex flex-col self-start tracking-tight">
                <div className="mr-9 font-bold mt-4">{doctorPrice ? doctorPrice.price : 'N/A'} /-</div>
              </div>
            </div>
            <div
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/adc93c4a6eb0d9c2d483e8a7f72ee0498ad784ea45692e232aedf7c6b41c119f?apiKey=8d6992485656477797592f8415f51272&"
              className="mt-1.5 w-full border border-black border-solid stroke-[1px] stroke-black max-md:max-w-full"
            />
            <div className="self-end mt-28 mr-7 text-base font-bold tracking-wider max-md:mt-10 mb-3 max-md:mr-2.5">
              Signature
            </div>
          </div>
          <div
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/adc93c4a6eb0d9c2d483e8a7f72ee0498ad784ea45692e232aedf7c6b41c119f?apiKey=8d6992485656477797592f8415f51272&"
            className="self-center mt-5 w-full border border-black border-solid max-w-[793px] stroke-[1px] stroke-black max-md:max-w-full"
          />
        </div>
        </div>
      </div>  </div>
      <div className=" ml-10 absolute top-[8px] left-[48px] text-base font-semibold leading-4 max-md:ml-5">
      <button
        className="absolute top-[58px] left-[750px] rounded-md h-10 bg-theme-white-default box-border w-[106px] flex flex-col items-start justify-start py-2.5 px-5 text-theme-primary-dark border-[1px] border-solid border-theme-primary-dark"
        onClick={handlePrint}
      >
        <div className="w-24 pb-20 mx-[!important] absolute top-3 left-[calc(50%_-_48px)] flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-5 relative ml-4 mb-4 overflow-hidden shrink-0"
            alt=""
            src={print}
          />
          <div className="absolute ml-11 mb-5">Print</div>
        </div>
      </button>
      <button
        className="absolute top-[58px] left-[870px] rounded-md h-10 bg-theme-white-default box-border w-[136px] flex flex-col items-start justify-start py-2.5 px-5 text-theme-primary-dark border-[1px] border-solid border-theme-primary-dark"
        onClick={() => generatePDF(pdfRef, {
          filename: "Appointment invoice.pdf",
        })}
      >
        <div className="w-24 pb-16 mx-[!important] absolute top-[calc(50%_-_12px)] left-[calc(50%_-_48px)] flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-5 relative ml-0 mb-4 overflow-hidden shrink-0"
            alt=""
            src={download}
          />
          <div className="absolute ml-8 mb-3">Download</div>
        </div>
      </button>
     {/* <button
        className="top-[45px] ml-10 rounded-md items-center justify-start py-2 px-4 border-[1px] border-solid border-royalblue w-48 mt-3 gap-[6px] leading-[10px] left-[920px] absolute font-medium bg-btn h-[42px] text-white"
        type="submit"
        onClick={generateFinalBill}
      >
        Generate final Bill
      </button>*/}
      <button
      className="top-[45px] ml-10 rounded-md items-center justify-start py-2 px-4 border-[1px] border-solid border-royalblue w-[116px] mt-3 gap-[6px] leading-[10px] left-[980px] absolute font-medium bg-btn h-10 text-white"
      type="submit"
      onClick={handle}
    >
      Cancel
    </button></div>
    </div>
  );
};


export default Appointment_Billing;
