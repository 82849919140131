import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import print from "../../../Data/print.svg";
import download from "../../../Data/download.svg";
import HospitalHeader from "../../../components/Billing/HospitalHeader";
import baseURL from "../../../assets/API_URL";
import axios from "axios";
import { useReactToPrint } from 'react-to-print';
import generatePDF from "react-to-pdf";
const BillDish = () => {
  const { pk } = useParams();
  
  const [data, setData] = useState([]);
  const targetRef = useRef();
  const pdfRef = useRef();
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const dischargeHistoryUrl = `${baseURL}/ipd/ipd-DischargeHistory/`;
  const patientApiUrl = `${baseURL}/api/patient/api/patients/`;
  const token = JSON.parse(localStorage.getItem("Token"));

  const fetchData = async () => {
    try {
      // Fetch discharge history data
      const response = await axios.get(dischargeHistoryUrl, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setData(response.data);
      // console.log("Discharge History Data:", response.data);

      // Filter the specific discharge record based on pk
      const Discharge = response.data.find(
        (app) => app.id === parseInt(pk, 10)
      );
      setFilterData(Discharge);

      if (Discharge) {
        // console.log("Filtered Discharge Data:", Discharge);
        const patientId = Discharge.patient;
        const patientResponse = await axios.get(
          `${patientApiUrl}${patientId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setPatientData(patientResponse.data);
        // console.log("Patient Data:", patientResponse.data);
      } else {
        // console.log("No discharge data found for pk:", pk);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pk]);
  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < dobDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "N/A";
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
    documentTitle: "Print Appointment",
    removeAfterPrint: true,
  });
  const handle = () => {
    navigate("/patient/IPD/Discharged_List/");
  };

  
  return (
    <div>
    
      <div className=" mx-40 mt-10"  ref={pdfRef}>
      <div  >
        <div className="flex overflow-hidden flex-col pt-5 pb-10 bg-white max-w-[869px]" ref={targetRef}>
          <div className="flex flex-col px-9 w-full text-black max-md:px-5 max-md:max-w-full" >
            <HospitalHeader></HospitalHeader>
            <div className="self-center mt-3 text-base font-bold tracking-wider">
              Patient Details
            </div>
          </div>
          <div className="flex flex-col px-10 mt-3 w-full max-md:px-5 max-md:max-w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b235b01e1a2e89c8858e000dd02bda477ecf7bd0f644fed1a8de5cc0db948b70?apiKey=8cd55a55d3fd4759ad0a38ee8bf55a48&&apiKey=8cd55a55d3fd4759ad0a38ee8bf55a48"
              className="object-contain w-[1000px] "
            />
            <div className="mt-4 w-full max-w-[756px] max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col">
                <div className="flex flex-col w-[62%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col self-stretch my-auto w-full text-sm font-medium text-black max-md:mt-10">
                    <div className="flex gap-5  max-w-full rounded-none w-[607px]">
                      <div>IPD no</div>
                      <div className="flex  ">
                        <div className="ml-[102px]">:</div>
                        <div className=" ml-5">
                          {filterData ? filterData.id : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[607px]">
                      <div className="">Patient’s Name</div>
                      <div className="flex  ">
                        <div className="ml-[50px]">:</div>
                        <div className=" ml-5">
                          {patientData ? patientData.fullname : "N/A"}
                        </div>
                      </div>
                    </div>
                  
                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[594px]">
                      <div>Admission Date</div>
                      <div className="flex gap-4 ">
                        <div className="ml-[45px] ">:</div>
                        <div className=" ml-1">
                          {formatDate(filterData.admission_date)}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[594px]">
                      <div>Age</div>{" "}
                      <div className="flex gap-4 ">
                        <div className="ml-[122px]">:</div>
                        <div className="">
                          {patientData ? calculateAge(patientData.DOB) : "N/A"}
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[594px]">
                      <div>Phone</div>
                      <div className="flex ">
                        <div className="ml-[105px]">:</div>
                        <div className=" ml-5">
                          {patientData ? patientData.phone_no : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-0 mr-40 w-[38%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col items-start w-full text-sm font-medium text-black max-md:mt-10">
                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[594px]">
                      <div>Email </div>
                      <div className="flex ">
                        <div className="ml-[35px]">:</div>
                        <div className=" ml-5 ">
                          {patientData ? patientData.email : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[594px]">
                      <div>Gender </div>
                      <div className="flex ">
                        <div className="ml-6"> :</div>
                        <div className=" ml-[20px] ">
                          {patientData ? patientData.Gender : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[594px]">
                      <div className="">DOB </div>
                      <div className="flex gap-3 ">
                        <div className="ml-[42px]">:</div>
                        <div className="ml-[10px] ">
                          {patientData ? formatDate(patientData.DOB) : "N/A"}
                        </div>
                      </div>
                    </div>
               
                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[594px]">
                      <div>Ref </div>
                      <div className="flex ">
                        <div className="ml-[51px]">:</div>
                        <div className="ml-[21px] ">
                          {patientData ? patientData.referred : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-5 mt-4 max-w-full rounded-none w-[594px]">
                      <div className="">Discharge Date </div>
                      <div className="flex  ">
                        <div>:</div>
                        <div className=" ml-[14px] ">
                          {formatDate(filterData.discharge_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b235b01e1a2e89c8858e000dd02bda477ecf7bd0f644fed1a8de5cc0db948b70?apiKey=8cd55a55d3fd4759ad0a38ee8bf55a48&&apiKey=8cd55a55d3fd4759ad0a38ee8bf55a48"
              className="object-contain mt-5 w-[1000] "
            />
            <div className="self-center mt-3 ml-3 text-base font-bold tracking-wider text-black">
              Discharge Summary{" "}
            </div>
          </div>
          <div className="flex flex-col items-start px-10 mt-3 w-full text-black max-md:px-5 max-md:max-w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b235b01e1a2e89c8858e000dd02bda477ecf7bd0f644fed1a8de5cc0db948b70?apiKey=8cd55a55d3fd4759ad0a38ee8bf55a48&&apiKey=8cd55a55d3fd4759ad0a38ee8bf55a48"
              className="object-contain self-stretch w-[1000] "
            />
            <div className="mt-3 text-sm font-bold">Diagnosis </div>
            <div className="mt-4 text-xs font-medium ">
              {filterData ? filterData.diagnosis : "N/A"}
            </div>
            <div className="mt-4 text-sm font-bold">C/O :</div>
            <div className="mt-4 text-xs font-medium ">
              {filterData ? filterData.co : "N/A"}
            </div>
            <div className="mt-3 text-sm font-bold">History :</div>
            <div className="mt-4 text-xs font-medium .">
              {filterData ? filterData.history : "N/A"}
            </div>
            <div className="mt-3 text-sm font-bold">Findings :</div>

            <div className="mt-4 text-xs font-medium .">
              {filterData ? filterData.finding : "N/A"}
            </div>

            <div className="flex gap-3 mt-3">
              <div className="grow text-sm font-bold">Operation Date :</div>
              <div className="text-xs mt-[3px] font-medium">
                {filterData ? formatDate(filterData.operation_date) : "N/A"}
              </div>
            </div>
            <div className="mt-3 text-sm font-bold">Operative Notes :</div>
            <div className="mt-4 text-xs font-medium max-md:max-w-full">
              {filterData ? filterData.operation_notes : "N/A"}
            </div>
            <div className="flex overflow-hidden flex-col items-start px py-11 text-xs font-medium text-black bg-white max-w-[869px] max-md:px-5">
              <div className="mt-0 text-sm font-bold">Course in hospital :</div>
              <div className="mt-4 text-xs font-medium max-md:max-w-full">
                {filterData ? filterData.course_in_hospital : "N/A"}
              </div>
              <div className="mt-7 text-sm font-bold">Investigations :</div>
              <div className="mt-4 ">
                {filterData ? filterData.investigation : "N/A"}
              </div>

              <div className="mt-7 text-sm font-bold">Treatment advised :</div>
              <div className="mt-4 ">
                {filterData ? filterData.treatment_advised : "N/A"}
              </div>
              <div className="mt-7 text-sm font-bold">Follow up :</div>
              <div className="mt-4 ">
                {filterData ? filterData.follow_up : "N/A"}
              </div>

              <div className="mt-8 text-sm font-bold">*Consent *</div>
              <div className="mt-3.5 max-md:max-w-full">
               
              </div>
              <div className="mt-14 text-sm font-bold max-md:mt-10">
                Patient’s / Relatives :
              </div>
              <div className="mt-5 text-sm font-bold">Name :</div>
              <div className="mt-5 text-sm font-bold">Signature :</div>
            </div>
            <div className="self-center mt-28 text-base font-extralight leading-loose text-center max-md:mt-10">
              Copyright © 2023 Created by CareChainAI
            </div>
          </div>
        </div> </div>
      </div>
      <div className=" ml-10 absolute top-[8px] left-[48px] text-base font-semibold leading-4 max-md:ml-5">
      <button
        className="absolute top-[58px] left-[750px] rounded-md h-10 bg-theme-white-default box-border w-[106px] flex flex-col items-start justify-start py-2.5 px-5 text-theme-primary-dark border-[1px] border-solid border-theme-primary-dark"
        onClick={handlePrint}
      >
        <div className="w-24 pb-20 mx-[!important] absolute top-3 left-[calc(50%_-_48px)] flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-5 relative ml-4 mb-4 overflow-hidden shrink-0"
            alt=""
            src={print}
          />
          <div className="absolute ml-11 mb-5">Print</div>
        </div>
      </button>
      <button
        className="absolute top-[58px] left-[870px] rounded-md h-10 bg-theme-white-default box-border w-[136px] flex flex-col items-start justify-start py-2.5 px-5 text-theme-primary-dark border-[1px] border-solid border-theme-primary-dark"
        onClick={() => generatePDF(pdfRef, {
          filename: "Appointment invoice.pdf",
        })}
      >
        <div className="w-24 pb-16 mx-[!important] absolute top-[calc(50%_-_12px)] left-[calc(50%_-_48px)] flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-5 relative ml-0 mb-4 overflow-hidden shrink-0"
            alt=""
            src={download}
          />
          <div className="absolute ml-8 mb-3">Download</div>
        </div>
      </button>
     {/* <button
        className="top-[45px] ml-10 rounded-md items-center justify-start py-2 px-4 border-[1px] border-solid border-royalblue w-48 mt-3 gap-[6px] leading-[10px] left-[920px] absolute font-medium bg-btn h-[42px] text-white"
        type="submit"
        onClick={generateFinalBill}
      >
        Generate final Bill
      </button>*/}
      <button
      className="top-[45px] ml-10 rounded-md items-center justify-start py-2 px-4 border-[1px] border-solid border-royalblue w-[116px] mt-3 gap-[6px] leading-[10px] left-[980px] absolute font-medium bg-btn h-10 text-white"
      type="submit"
      onClick={handle}
    >
      Cancel
    </button></div>
    </div>
  );
};

export default BillDish;
